import router from './router'
import store from './store'

router.beforeEach(async (to, from, next) => {
  // 重新取得登入資訊
  if (!store.getters['user/userData']) {
    await store.dispatch('user/getInfo')
  }
  const user =
    store.getters['user/userData'] ||
    JSON.parse(localStorage.getItem('user') || '{}')

  // 已登入
  if (to.path === '/pages/login' && user && user.access_token) {
    next({ path: '/' })
    return
  }
  const role = Object.values(to.meta.role || {})
  if (Object.keys(role).length === 0) {
    // 不須權限
    next()
    return
  }
  if (!user.role || !user.role.role) {
    // 無使用者資訊
    console.log('no user')
    next({ path: '/login' })
    return
  }

  role.push('SYS_ADMIN')
  const hasPermission = role.includes(user.role.role)
  if (!hasPermission) {
    // 無權限
    console.log('no role Permission')
    alert('無權限瀏覽')
    // localStorage.clear()
    next({ path: '/' })
    return
  }
  // try {
  //   const login = from.name === 'Login'
  //   // left side bar
  //   if (login || !store.getters['page/tags']) {
  //     store.dispatch('page/getTags')
  //   }
  //   // if (login || !store.getters['building/buildings']) {
  //   //   store.dispatch('building/getAllBuildings')
  //   // }
  // } catch (error) {
  //   console.error(error)
  //   localStorage.clear()
  //   next({ path: '/login' })
  //   throw error
  // }
  next() // 有權限
  return
})
