import settings from '@/settings'
import FormData from 'form-data'

/**
 * 使用者
 */
class User {
  /**
   * 登入
   */
  login(username, password) {
    const form = new FormData()
    form.append('grant_type', 'password')
    form.append('username', username)
    form.append('password', password)

    return axios.post(settings.api.fullPath + `/oauth/token`, form, {
      headers: {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMw=='
      }
    })
  }

  /**
   * refresh token
   */
  refreshToken(refresh_token) {
    const form = new FormData()
    form.append('grant_type', 'refresh_token')
    form.append('refresh_token', refresh_token)

    return axios.post(settings.api.fullPath + `/oauth/token`, form, {
      headers: {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMw=='
      }
    })
  }

  /**
   * switch school
   */
  switchSchool(access_token, school_id) {
    const form = new FormData()
    form.append('grant_type', 'password')
    form.append('username', '_SWITCH_SCHOOL_')
    form.append('password', access_token)
    form.append('switch_school', school_id)

    return axios.post(settings.api.fullPath + `/oauth/token`, form, {
      headers: {
        Authorization: 'Basic Y2xpZW50YXBwOjEyMw=='
      }
    })
  }

  /**
   * 登出
   */
  logout(access_token, refresh_token) {
    return axios.patch(settings.api.fullPath + `/user/token`, {
      access_token,
      refresh_token
    })
  }

  /**
   * openfire 帳密取得
   */
  openfire() {
    return axios.get(settings.api.fullPath + `/user/openfire`)
  }

  /**
   * 使用者角色
   */
  roles() {
    return axios.get(settings.api.fullPath + `/user/roles`)
  }

  /**
   * 使用者權限資訊
   */
  authorities() {
    return axios.get(settings.api.fullPath + `/user/authority`)
  }

  /**
   * 告警通知資訊
   */
  getNotifySettings() {
    return axios.get(settings.api.fullPath + `/user/notify`)
  }

  /**
   * 更新告警通知資訊
   */
  updateNotifySettings(params) {
    return axios.put(settings.api.fullPath + `/user/notify`, params)
  }
}

var user = new User()
export default user
