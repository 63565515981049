import jwt_decode from 'jwt-decode'
import User from '@/api/user'

// xmpp
import Xmpp from '@/vendor/xmpp'
window.xmppClient = new Xmpp()

const state = {
  userData: null,
  authorities: [],
  openfire: null,
  ip: null
}

const mutations = {
  SET_USERDATA: (state, userData) => {
    state.userData = userData
  },
  SET_ROLES: (state, role) => {
    state.userData.role = role
  },
  SET_OPENFIRE: (state, openfire) => {
    state.openfire = openfire
  },
  SET_AUTHORITIES: (state, authorities) => {
    state.authorities = authorities
  },
  SET_IP: (state, ip) => {
    state.ip = ip
    axios.defaults.headers.common['Client-IP'] = ip
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo = { username: null, password: null }) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      User.login(username, password)
        .then(async (response) => {
          if (!response.data.access_token) {
            console.log('reject')
            reject(response.data)
          }
          const content = response.data
          const decoded = jwt_decode(content.access_token)
          const userData = {
            ...content,
            ...decoded,
            role: {}
          }
          commit('SET_USERDATA', userData)
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + userData.access_token
          await dispatch('getRoles')
          await dispatch('getOpenfire')
          await dispatch('updateLocalStorageData')
          await dispatch('page/getTags', '', { root: true })
          resolve(response)
        })
        .catch(function (error) {
          reject(error.response || error)
        })
    })
  },

  refreshToken({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      const refresh_token = JSON.parse(
        localStorage.getItem('user')
      ).refresh_token
      User.refreshToken(refresh_token)
        .then(async (response) => {
          if (!response.data.access_token) {
            console.log('reject')
            reject(response.data)
          }
          const content = response.data
          const decoded = jwt_decode(content.access_token)
          const userData = {
            ...content,
            ...decoded,
            role: state.userData.role
          }
          commit('SET_USERDATA', userData)
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + userData.access_token
          await dispatch('updateLocalStorageData')
          resolve(response)
        })
        .catch(function (error) {
          reject(error.response || error)
        })
    })
  },

  switchSchool({ commit, dispatch, state }, school_id) {
    return new Promise((resolve, reject) => {
      User.switchSchool(state.userData.access_token, school_id)
        .then(async (response) => {
          if (!response.data.access_token) {
            console.log('reject')
            reject(response.data)
          }
          await User.logout(
            state.userData.access_token,
            state.userData.refresh_token
          )
          const content = response.data
          const decoded = jwt_decode(content.access_token)
          const userData = {
            ...content,
            ...decoded,
            role: state.userData.role
          }
          commit('SET_USERDATA', userData)
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + userData.access_token
          await dispatch('updateLocalStorageData')
          resolve(response)
        })
        .catch(function (error) {
          reject(error.response || error)
        })
    })
  },

  getRoles({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: getRolesData } = await User.roles()
        if (getRolesData) {
          commit('SET_ROLES', {
            role: getRolesData[0].name,
            name: getRolesData[0].remarks,
            district_id: state.userData.district_id || undefined,
            school_id: state.userData.school_id || undefined,
            class_id: state.userData.classroom_id || undefined
          })
          const { data: getAuthoritesData } = await User.authorities()
          const authorities = getAuthoritesData
          // authorities.push('LOGOUT')
          commit('SET_AUTHORITIES', authorities)
          resolve(getRolesData.data)
        }
      } catch (error) {
        reject(error.response || error)
      }
    })
  },
  getOpenfire({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      User.openfire()
        .then(async (response) => {
          commit('SET_OPENFIRE', response.data)
          resolve(response.data)
        })
        .catch(function (error) {
          // reject(error.response || error)
          resolve(error.response)
        })
    })
  },
  updateLocalStorageData({ state }) {
    localStorage.setItem('user', JSON.stringify(state.userData))
    localStorage.setItem('openfire', JSON.stringify(state.openfire))
    localStorage.setItem('authorities', JSON.stringify(state.authorities))
  },
  // user logout
  async logout({ commit }) {
    function isJSON(str) {
      try {
        return JSON.parse(str) && !!str
      } catch (e) {
        return false
      }
    }
    const userData =
      (isJSON(localStorage.getItem('user')) &&
        JSON.parse(localStorage.getItem('user'))) ||
      JSON.parse('{}')
    if (userData.access_token && userData.refresh_token) {
      await User.logout(userData.access_token, userData.refresh_token)
    }
    localStorage.setItem('user', '{}')
    localStorage.setItem('openfire', '{}')
    localStorage.setItem('authorities', '[]')
    commit('SET_USERDATA', null)
    commit('SET_OPENFIRE', null)
    commit('SET_AUTHORITIES', null)
    axios.defaults.headers.common['Authorization'] = ''
    window.xmppClient.stop()
  },
  async getInfo({ commit, state, dispatch }) {
    function isJSON(str) {
      try {
        return JSON.parse(str) && !!str
      } catch (e) {
        return false
      }
    }
    const userData =
      (isJSON(localStorage.getItem('user')) &&
        JSON.parse(localStorage.getItem('user'))) ||
      JSON.parse('{}')
    const openfire =
      (isJSON(localStorage.getItem('openfire')) &&
        JSON.parse(localStorage.getItem('openfire'))) ||
      JSON.parse('{}')
    const authorities =
      (isJSON(localStorage.getItem('authorities')) &&
        JSON.parse(localStorage.getItem('authorities'))) ||
      JSON.parse('[]')
    if (userData && userData !== state.userData && userData.access_token) {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + userData.access_token
      commit('SET_USERDATA', userData)
      commit('SET_OPENFIRE', openfire)
      commit('SET_AUTHORITIES', authorities)

      // start xmpp
      if (
        state.openfire &&
        state.openfire.username &&
        state.openfire.password
      ) {
        window.xmppClient.start(
          state.openfire.username,
          state.openfire.password
        )
      }
    } else {
      dispatch('logout')
      return
    }
    return userData
  },
  async getIP({ commit }) {
    const response = await fetch('https://api.ipify.org/?format=json')
    const data = await response.json()

    if (response.status === 200) {
      commit('SET_IP', data.ip)
    } else {
      commit('SET_IP', 'Failed to Obtain IP Address')
    }
  }
}
const getters = {
  userData: (state) => state.userData,
  authorities: (state) => state.authorities || [],
  userRole: (state) => (state.userData ? state.userData.role || '' : ''),
  userPermission: (state) => ({
    role: state.userData?.name || null,
    districtId: state.userData?.district_id || null,
    schoolId: state.userData?.school_id || null,
    classId: state.userData?.classroom_id || null,
    openfireId: state.openfire?.username,
    openfireSecret: state.openfire?.password
  }),
  ip: (state) => state.ip
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
