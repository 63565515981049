const classOptionContainer = () =>
  import('@/views/containers/classOptionContainer')

export default {
  path: '/class',
  redirect: '/',
  component: classOptionContainer,
  props: true,
  meta: {
    role: ['CLASS_USER', 'SCH_AFFAIRS'],
    permission: ['GET_CLASS', 'LOGOUT']
  },
  children: [
    {
      path: ':tagId',
      redirect: ':tagId/overview',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      meta: {
        role: [
          'CITY_ADMIN',
          'SCH_ADMIN',
          'SCH_USER',
          'CLASS_USER',
          'SCH_AFFAIRS'
        ],
        permission: [
          'GET_AC',
          'GET_CLASS_COMPETITION',
          'GET_CLASS',
          'GET_CARD_READER',
          'GET_CLASS_METER_HISTORY',
          'GET_CLASS_WARNING',
          'LOGOUT'
        ]
      },
      children: [
        {
          path: 'overview',
          props: true,
          component: () => import('@/views/classroom/overview'),
          meta: {
            bgCSS: 'BG-02',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: [
              'GET_AC',
              'GET_CLASS',
              'GET_CLASS_METER_HISTORY',
              'GET_CLASS_WARNING',
              'LOGOUT'
            ]
          }
        },
        {
          path: 'comparison',
          props: true,
          component: () => import('@/views/classroom/comparison/index'),
          meta: {
            bgCSS: 'BG-02',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ]
          }
        },
        {
          path: 'info',
          props: true,
          component: () => import('@/views/classroom/info/index'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: ['GET_CLASS', 'LOGOUT']
          }
        },
        {
          path: 'deposit',
          props: true,
          component: () => import('@/views/classroom/deposit'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: ['GET_CARD_READER', 'GET_CLASS_WARNING', 'LOGOUT']
          }
        },
        {
          path: 'warning',
          props: true,
          name: 'ClassWarning',
          component: () => import('@/views/classroom/warning'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: ['GET_CLASS_WARNING', 'LOGOUT']
          }
        },
        {
          path: 'action',
          props: true,
          component: () => import('@/views/classroom/action'),
          meta: {
            name: '冷氣控制',
            bgCSS: 'BG-01',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: [
              'GET_AC',
              'GET_CLASS',
              'GET_CLASS_METER_HISTORY',
              'LOGOUT'
            ]
          }
        },
        {
          path: 'report',
          props: true,
          component: () => import('@/views/classroom/report/index'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: ['GET_CLASS_METER_HISTORY', 'LOGOUT']
          }
        },
        {
          path: 'chart',
          props: true,
          component: () => import('@/views/classroom/chart'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: [
              'GET_AC',
              'GET_CLASS',
              'GET_CARD_READER',
              'GET_CLASS_METER_HISTORY',
              'LOGOUT'
            ]
          }
        },
        {
          path: 'analysis',
          props: true,
          component: () => import('@/views/classroom/Analysis'),
          meta: {
            bgCSS: 'BG-04',
            role: [
              'CITY_ADMIN',
              'SCH_ADMIN',
              'SCH_USER',
              'CLASS_USER',
              'SCH_AFFAIRS'
            ],
            permission: [
              'GET_AC',
              'GET_CLASS',
              'GET_CARD_READER',
              'GET_CLASS_METER_HISTORY',
              'LOGOUT'
            ]
          }
        }
        // {
        //   path: 'air',
        //   props: true,
        //   component: () => import('@/views/classroom/Air'),
        //   meta: {
        //     bgCSS: 'BG-04',
        //     role: [
        //       'CITY_ADMIN',
        //       'SCH_ADMIN',
        //       'SCH_USER',
        //       'CLASS_USER',
        //       'SCH_AFFAIRS'
        //     ],
        //     permission: [
        //       'GET_AC',
        //       'GET_CLASS',
        //       'GET_CARD_READER',
        //       'GET_CLASS_METER_HISTORY',
        //       'LOGOUT'
        //     ]
        //   }
        // }
      ]
    }
  ]
}
